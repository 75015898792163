<template>
	<div id="office-wrapper">
		<div class="qualityManual-container" v-if="option.url">
			<vab-only-office :option="option" />
		</div>
	</div>
</template>

<script>
import { addJs } from "@/utils/util"
import vabOnlyOffice from "./OnlyOffice.vue"

export default {
	name: "office-wrapper",
	components: {
		vabOnlyOffice
	},
	data() {
		return {
			// 参考vabOnlyOffice组件参数配置
			option: {
				url: "",
				isEdit: "",
				fileType: "",
				title: "",
				lang: "",
				isPrint: "",
				user: { id: null, name: "" }
			},
			show: false,
			officeSrc: ""
		}
	},
	computed: {
		getRandomId() {
			return `prev${new Date().getTime().toString(32).toUpperCase()}`
		}
	},
	mounted() {
		const objStr = sessionStorage.getItem("docOfficeInfo")
		const obj = objStr ? JSON.parse(objStr) : {}
		this.officeSrc = obj.officeSrc

		addJs(this.officeSrc)
			.then(() => {
				this.option = {
					...obj,
					key: this.getRandomId,
					// url: `http://${obj.ip}:${obj.port}/office/getFile?docfileId=${obj.id}`,
					// editUrl: `http://${obj.ip}:${obj.port}/office/docx/save?docfileId=${obj.id}`,
					url: `${obj.urlLink}/office/getFile?docfileId=${obj.id}`,
					editUrl: `${obj.urlLink}/office/docx/save?docfileId=${obj.id}`,
					isEdit: true,
					fileType: obj.suffix,
					title: obj.name,
					lang: "zh-CN",
					isPrint: true,
					user: { id: obj.userId, name: obj.userName }
				}
			})
			.finally(() => {})
	},
	methods: {}
}
</script>

<style>
html,
body {
	height: 100%;
}
#office-wrapper {
	/*font-family: Avenir, Helvetica, Arial, sans-serif;*/
	/*-webkit-font-smoothing: antialiased;*/
	/*-moz-osx-font-smoothing: grayscale;*/
	text-align: center;
	color: #2c3e50;
	height: 100%;
}
.qualityManual-container {
	padding: 0 !important;
	width: 100%;
	height: 100%;
}
.qualityManual-container-office {
	width: 100%;
	height: 100%;
}
</style>
